<template>
  <div class="page-box box">
    <div class="add-out-box box">
      <div
        class="add-box"
        @click="$router.push('/user/addStudent')"
      >
        <div class="icon-box">
          <van-icon name="plus" />
        </div>
        添加学员
      </div>
    </div>

    <!-- 列表 -->
    <div class="list-box box">
      <template v-for="(item, index) in childList">
        <van-swipe-cell :key="index">
          <template v-if="childList && childList.length > 0">
            <div
              class="item-box box"
              :class="{'select-item-box': pageStatus === 'select'}"
            >
              <van-radio-group
                v-if="pageStatus === 'select'"
                v-model="radio"
                class="radio-box"
              >
                <van-radio :name="item.childNo"></van-radio>
              </van-radio-group>
              <div class="text-a">
                {{ item.childName || '--' }}
              </div>
              <div class="text-b">
                出生日期：{{ item.birthDay || '--' }}
              </div>
              <div class="text-c">
                与血缘关系：{{ item.relation == 1 ? '父母' : item.relation == 2 ? '亲属' : '其他' }}
              </div>
              <div class="text-d">
                身份证号：{{ item.idCardNo || '--' }}
              </div>
              <div
                class="text-btn"
                @click="editChild(item)"
              >
                修改
              </div>
              <van-divider style="margin-bottom: 0;" />
            </div>
          </template>
          <template v-else>
            <van-empty
              description="暂无学员"
            />
          </template>
          <template #right>
            <van-button
              square
              style="height: 100%;"
              text="删除"
              type="danger"
              @click="childDel(item)"
            />
          </template>
        </van-swipe-cell>
      </template>
    </div>

    <!-- 如果是 下单进入下一步 -->
    <van-button
      v-if="pageStatus === 'select'"
      class="submit-btn"
      color="#51B464"
      round
      type="info"
      @click="nextFun"
    >
      下一步
    </van-button>
  </div>
</template>

<script>
export default {
  name: 'StudentList',
  components: {
  },
  data () {
    return {
      pageStatus: '',
      radio: '',
      childList: []
    }
  },
  computed: {
  },
  async created () {
    this.pageStatus = this.$route.query.pageStatus
    await this.getList()
    if (this.pageStatus === 'select' && this.childList && this.childList.length > 0) {
      this.radio = this.childList[0].childNo || ''
    }
  },
  methods: {
    async getList () {
      let data = {
        pageNo: this.pageNo,
        pageSize: 100
      }
      let res = await this.$http.userApi.userChildrenList(data)
      this.childList = res.msg.data || []
    },
    editChild (item) {
      window.localStorage.setItem('studentItem', JSON.stringify(item))
      this.$router.push('/user/addStudent?id=' + item.childNo)
    },
    nextFun () {
      let orderInfo = JSON.parse(window.localStorage.getItem('orderInfo') || '{}')
      orderInfo.childNo = this.radio
      window.localStorage.setItem('orderInfo', JSON.stringify(orderInfo))
      // this.$router.push('/school/uploadInfo?id=' + this.$route.query.courseId)
      this.$router.push('/school/orderPay?id=' + this.$route.query.courseId)
    },
    async childDel (item) {
      this.$dialog.confirm({
        title: '删除学员',
        message: `确认删除${item.childName}此学员？`,
      })
      .then(() => {
        // on confirm
        this.$http.userApi.childDel({id: item.childNo}).then((res) => {
          if (res.code === 200) {
            this.$toast.success('删除成功')
            this.getList()
          }
        })
      })
      .catch(() => {
        // on cancel
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding: 0 20px;
  padding-bottom: 74px;
  .add-out-box {
    line-height: 16px;
    padding-top: 16px;
    .add-box {
      float: right;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      vertical-align: middle;
      .icon-box {
        float: left;
        margin-right: 2px;
        // border: 1px solid #262626;
      }
    }
  }

  .list-box {
    .item-box {
      padding-top: 16px;
      position: relative;
      .text-a {
        font-size: 16px;
        line-height: 34px;
        font-weight: bold;
        color: #51B464;
      }
      .text-b, .text-c ,.text-d {
        line-height: 16px;
        font-size:14px;
        margin-top: 12px;
        color: #646464;
      }
      .text-btn {
        position: absolute;
        right: 0;
        top: 13px;
        color: #D5423E;
        padding: 10px;
        font-size: 13px;
      }
    }
    .select-item-box {
      padding-left: 60px;
      .radio-box {
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -10px;
      }
    }
  }
  .submit-btn {
    width: 346px;
    position: fixed;
    left: 50%;
    margin-left: -173px;
    bottom: 20px;
    display: block;
  }
}
</style>
